<template>
  <div class="goods_wrapper">
    <div class="top_title">
      <div class="left">
        <div class="title">根据导入内容，核对收件人信息<span style="color: red">(店铺导入显示的密文不是报错，平台可以直接用密文打单)</span>
          &nbsp;
          <a-tooltip placement="top">
            <template slot="title">
              <span>因官方对收货人信息敏感处理，现已对收货人信息加密处理，加密数据可以正常下单！</span>
            </template>
            <a-icon type="question-circle" style="color: #1890FF"/>
          </a-tooltip>
        </div>
        <div class="count">共上传{{ dataSource.length }}条数据</div>
      </div>

      <div class="action">
        <span
          @click="applyDecode"
          v-show="firstTypeId == 'PDD'"
          class="decode_btn"
        >出现密文点击此处申请解密额度</span
        >
        <a-button @click="clearNoSendKey">清除含禁发词订单</a-button>
        <a-button v-if="secondTypeId === 1" @click="batchRemove">批量删除</a-button>
        <a-button style="margin-left:12px" @click="gobackHandle">重新上传</a-button>
        <a-button
          type="primary"
          style="margin-left:12px"
          @click="gotopHandle"
        >信息正确,下一步</a-button
        >
      </div>
    </div>

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :row-selection="secondTypeId === 1 ?{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      } : undefined"
      :pagination="{ current, pageSize }"
      :rowKey="(record) => record.id"
      :scroll="{ x: 1200 }"
      @change="change"
    >
      <template slot="name" slot-scope="text">
        {{ sreReplace(text, '##', '（姓名已加密）') }}
      </template>
      <template slot="addressStr" slot-scope="text">
        {{ sreReplace(text, '##', '***（地址已加密）') }}
      </template>
      <template slot="pic_path" slot-scope="text, record">
        <img v-if="record.child" :src="record.child[0].pic_path || record.child[0].productPic" style="width: 60px; height: 60px"/>
        <span v-else>-</span>
      </template>
      <template slot="mobile" slot-scope="text, record">
        <span style="color:#f93244" v-if="record.virtual">
          {{ sreReplace(text, '$$', '（手机号已加密）') }}
        </span>
        <span v-else>
          {{ sreReplace(text, '$$', '（手机号已加密）') }}
        </span>
      </template>
      <template slot="noteColor" slot-scope="text, record">
        <div
          v-if="firstTypeId === 'DOUYIN'"
          :class="`tragtb${dyFlag[record.noteColor]}`"
          style="width: 20px; height: 20px; line-height: 20px"
        />
        <div
          v-else
          :class="`tragtb${record.noteColor}`"
          style="width: 20px; height: 20px; line-height: 20px"
        ></div>
      </template>
      <template slot="key" slot-scope="text, record, index">
        <div>
          {{ (current - 1) * pageSize + index + 1 }}
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a @click="editDetail(record)">编辑</a>
        <a
          style="margin-left: 40px;color:#F93244"
          @click="handleDelete(record)"
        >删除</a
        >
      </template>
    </a-table>
    <div
      v-show="firstTypeId == 'PDD' && dataSource.length > 0"
      class="virtual_verify"
    >
      <span :class="virtualIndex.length == 0 ? 'virtual_msg' : 'virtual_err'">
        {{ virtualMsg }}
      </span>
      <div class="btn_wrapper">
        <a-button
          @click="reRevalidate(dataSource)"
          style="margin-right:8px"
        >重新验证</a-button
        >

        <a-button
          v-show="virtualIndex.length > 0"
          @click="handleChangeMobile"
          type="primary"
          style="margin-right:8px"
        >一键修改统一手机号</a-button
        >
        <a-input
          v-show="virtualIndex.length > 0"
          style="width:130px"
          v-model.trim="modifyVirtual"
        />
      </div>
    </div>

    <a-modal
      title="编辑收货人信息"
      :width="720"
      :visible="edit.show"
      :maskClosable="false"
      @cancel="editCancel"
      @ok="editOk"
    >
      <div class="input_group">
        <span>订单编号：</span>
        <a-input class="top5" placeholder="订单编号" v-model="edit.orderNo" />
      </div>
      <div class="input_group">
        <span>收货人姓名：</span>
        <a-input class="top5" placeholder="姓名" v-model="edit.name" />
      </div>
      <div class="input_group">
        <span>手机号码：</span>
        <a-input class="top5" placeholder="电话" v-model="edit.mobile" />
      </div>

      <div class="input_group">
        <span>省：</span>
        <a-input class="top5" placeholder="省" v-model="edit.province" />
      </div>
      <div class="input_group">
        <span>市：</span>
        <a-input class="top5" placeholder="市" v-model="edit.city" />
      </div>
      <div class="input_group">
        <span>县：</span>
        <a-input class="top5" placeholder="县" v-model="edit.county" />
      </div>
      <div class="input_group">
        <span>详细地址：</span>
        <a-input class="top5" placeholder="详细地址" v-model="edit.address" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sreReplace } from '@/utils/util'
const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    scopedSlots: { customRender: 'key' },
    fixed: 'left',
    width: 80
  },
  {
    title: '主图',
    dataIndex: 'pic_path',
    scopedSlots: { customRender: 'pic_path' },
    width: 200
  },
  {
    title: '订单编号',
    dataIndex: 'orderNo',
    width: 200
  },
  {
    title: '收货人姓名',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    width: 240
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' },
    width: 120
  },
  {
    title: '收货地址',
    dataIndex: 'addressStr',
    scopedSlots: { customRender: 'addressStr' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    fixed: 'right',
    width: 140
  }
]

export default {
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    firstTypeId: {
      type: String
    },
    secondTypeId: {
      type: Number
    }
  },
  data () {
    return {
      current: 1,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRows: [],
      modifyVirtual: '13093856040',
      virtualIndex: [],
      virtualMsg: '',
      edit: { show: false },
      columns,
      page: { pageNum: 1, pageSize: 10, total: this.dataSource.length },
      // 抖音旗子颜色
      dyFlag: { 0: 0, 1: 5, 2: 4, 3: 3, 4: 2, 5: 1 }
    }
  },
  watch: {
    dataSource: {
      handler (newVal) {
        // 虚拟号判断
        if (this.firstTypeId === 'PDD' && this.dataSource.length > 0) {
          if (this.secondTypeId === 1) {
            this.handleRevalidation(newVal)
          } else {
            this.handleRevalidationOther(newVal)
          }
        }
        this.current = 1
        // 停发
      },
      deep: true,
      immediate: true
    },
    secondTypeId: {
      handler (newVal) {
        console.log('[watch] ---> ', typeof newVal)
        if (newVal === 1) {
          const flag = this.columns.some(val => val.dataIndex === 'noteColor')
          if (!flag) {
            this.columns.splice(1, 0, {
              title: '旗帜',
              dataIndex: 'noteColor',
              scopedSlots: { customRender: 'noteColor' },
              width: 100
            })
          }
        } else {
          const flag = this.columns.some(val => val.dataIndex === 'noteColor')
          if (flag) {
            this.columns.splice(1, 1)
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    sreReplace,
    change (pagination) {
      const { current } = pagination
      this.current = current
    },
    clearNoSendKey () {
      this.$emit('clearNoSendKey')
    },
    batchRemove () {
      console.log('[this.selectedRows] ---> ', this.selectedRows)
      this.$emit('deleteRows', this.selectedRows)
    },
    onSelectChange (keys, selectedRows) {
      this.$emit('selectedHandle', selectedRows)
      this.selectedRowKeys = keys
      this.selectedRows = selectedRows.map(val => val.orderNo)
    },
    applyDecode () {
      window.open('https://fuwu.pinduoduo.com/service-market/decrypt')
    },
    gobackHandle () {
      this.$emit('gobackHandle')
    },
    gotopHandle () {
      this.$emit('gotopHandle')
    },
    editDetail (record) {
      record.show = true
      this.edit = JSON.parse(JSON.stringify(record))
    },
    editCancel () {
      this.edit = { show: false }
    },
    reRevalidate (arr) {
      if (this.secondTypeId === 1) {
        this.handleRevalidation(arr, 2)
      } else {
        this.handleRevalidationOther(arr, 2)
      }
    },
    handleRevalidation (arr, type) {
      this.virtualIndex = []
      arr.forEach((item, index) => {
        if (item.virtual) {
          this.virtualIndex.push(index + 1)
        }
      })
      if (this.virtualIndex.length === 0) {
        this.virtualMsg = '*无虚拟手机号'
      } else {
        this.virtualMsg = `*共${this.virtualIndex.length}条拼多多数据为虚拟手机号，应拼多多官方要求，虚拟手机号生成不了面单号，请联系买家索要真实手机号点击编辑进行修改！`
      }
      if (type === 2) {
        this.$message.success('已重新验证')
      }
    },
    handleRevalidationOther (arr, type) {
      arr.forEach((item) => {
        if (/^1846694\d{4}$/.test(item.mobile)) {
          item.virtual = true
        } else {
          item.virtual = false
        }
      })
      if (type === 2) {
        this.handleRevalidation(arr, 2)
      } else {
        this.handleRevalidation(arr)
      }
    },
    handleChangeMobile () {
      this.dataSource.forEach((item) => {
        if (item.virtual) {
          item.mobile = this.modifyVirtual
        }
      })
      this.$message.success('修改成功')
      this.$emit('changeMobile', this.dataSource)
    },
    editOk () {
      const { dataSource, edit } = this
      edit.show = false
      edit.addressStr = edit.province + edit.city + edit.county + edit.address
      dataSource.forEach((item, index) => {
        item.key = index + 1
        if (item.id === edit.id) {
          dataSource[index] = edit
        }
      })

      this.$emit('deleteData', dataSource)
    },

    handleDelete (record) {
      const { dataSource } = this
      const newData = dataSource.filter((item) => item.id !== record.id)
      newData.forEach((item, index) => {
        item.key = index + 1
      })
      this.$emit('deleteData', newData)
    }
  }
}
</script>

<style lang="less" scoped>
.goods_wrapper {
  font-family: PingFang SC;
}

.top_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
}
.left {
  display: flex;
  align-items: center;
  .title {
    margin-right: 24px;
    font-size: 16px;
    font-weight: 500;
  }
  .count {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.input_group {
  display: flex;
  // align-items: center;
  span {
    margin-top: 5px;
    width: 88px;
    text-align: right;
  }
}
.top5 {
  margin-bottom: 8px;
  width: 560px;
}
.decode_btn {
  color: #40a9ff;
  margin: 0 12px;
  cursor: pointer;
}
.virtual_verify {
  display: flex;
  align-items: center;
}
.virtual_msg {
  color: #40a9ff;
  margin-right: 8px;
}
.btn_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.virtual_err {
  color: #f93244;
  margin-right: 8px;
}
.tragtb1 {
  background: url("~@/assets/css_sprites.png") -354px -570px;
}
.tragtb2 {
  background: url("~@/assets/css_sprites.png") -391px -570px;
}
.tragtb3 {
  background: url("~@/assets/css_sprites.png") -465px -570px;
}
.tragtb4 {
  background: url("~@/assets/css_sprites.png") -428px -570px;
}
.tragtb5 {
  background: url("~@/assets/css_sprites.png") -502px -570px;
}
</style>
